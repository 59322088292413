import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Body","CraftStickyHeader","SearchParamsToastMessage","IsInPersonalizationSettings","IsUpgradingAccount","UpgradeAccountCard","SelectListDialog","CreateNewListCard"] */ "/vercel/path0/src/app/components.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/email-code-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyRecipesScreen"] */ "/vercel/path0/src/app/my-recipes-screen.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageSessionStoreProvider"] */ "/vercel/path0/src/app/page-session-store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IsSettingProfileName","ProfileNameCard"] */ "/vercel/path0/src/app/profile-name-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApplicationProvider"] */ "/vercel/path0/src/app/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuizPromptCard"] */ "/vercel/path0/src/app/quiz-prompt-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SignInCard"] */ "/vercel/path0/src/app/sign-in-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IsSigningUp","SignUpCard"] */ "/vercel/path0/src/app/sign-up-card.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/feedback/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ResponsiveDialog","ResponsiveDialogOverlay","ResponsiveDialogContent"] */ "/vercel/path0/src/components/layout/responsive-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/src/components/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppMatches"] */ "/vercel/path0/src/components/util/app-matches.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageSessionMatches"] */ "/vercel/path0/src/components/util/page-session-matches.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionSnapshotMatches"] */ "/vercel/path0/src/components/util/session-matches.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ActorProvider"] */ "/vercel/path0/src/lib/actor-kit/components.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SafariInstallPrompt"] */ "/vercel/path0/src/modules/pwa-install/safari-install-prompt.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
